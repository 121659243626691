/* * {
  box-sizing: border-box;
} */

.imgZoomContainer {
  position: relative;
}

.imgZoomContainer:hover .imgZoomLens {
  border: 1px solid #d4d4d4;
}

.imgZoomLens {
  position: absolute;
  /* border: 1px solid #d4d4d4; */
  /*set the size of the lens:*/
  width: 100px;
  height: 100px;
}

.imgZoomResult {
  /* border: 1px solid #d4d4d4; */
  /* border: 1px solid #171717; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: absolute;
  z-index: 100;
  /*set the size of the result div:*/
  width: 35rem;
  height: 25rem;
  margin-right: 0.8rem;
}
