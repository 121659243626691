.CarouselItem {
    height: 300px;
}

.CarouselItem-img-wrapper {
    width: 50% ;
    height: 300px;
}

.CarouselItem-img {
    object-fit: overflow;
}

.CarouselItem-description {
    width: 50%;
    height: 300px;
    font-size: 1.4rem;
    justify-content: flex-start;
}

.CarouselItem-shortdescription {
    font-size: 2rem;
    background-color: rgb(1, 1, 36);
    padding-top: 2rem;
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 1rem;
    align-items: flex-start;
}

.CarouselItem-longdescription {
    padding-right: 2rem;
    padding-left: 2rem;
    /* background-image: linear-gradient(100deg, rgb(1, 1, 36), rgb(5, 80, 155)); */
    background-image: white;
    justify-content: flex-start;
}

.CarouselItem-button {
    padding: 10px; 
    justify-content: space-evenly;
    width: 150px;
    font-size: 14px;
    border-radius: 8px;
}

.flex {
    display: flex;
}

.flex-col {
    flex-direction: column;
}

.h-1 {
    height: 100%;
}

.w-1 {
    width: 100%;
}